export const blogList = currentLanguage => [
  {
    title: {
      en: "Goodbye to manual reporting!",
      tr: "Fiziksel raporlara elveda!",
    },
    id: `good-bye-manual-${currentLanguage}`,
    data: {
      en: "September 16, 2021",
      tr: "Eylül 16, 2021",
    },
    author: "Peny",
    authorImage:
      "https://media-exp1.licdn.com/dms/image/C4E0BAQGIWKhm4dCqIA/company-logo_200_200/0/1609746202791?e=1656547200&v=beta&t=1T2uU6-cLPmvNzB9pNn0-sC7U1H3-fmYUPsG-BUeAmo",
    blogImage:
      "https://website-blog-pictures.s3.eu-central-1.amazonaws.com/1631782599938.png",
  },
  {
    title: {
      en: "-",
      tr: "Masraf sahteciliği",
    },
    id: `fake-expenses-${currentLanguage}`,
    data: {
      en: "September 14, 2021",
      tr: "Eylül 14, 2021",
    },
    author: "Peny",
    authorImage:
      "https://media-exp1.licdn.com/dms/image/C4E0BAQGIWKhm4dCqIA/company-logo_200_200/0/1609746202791?e=1656547200&v=beta&t=1T2uU6-cLPmvNzB9pNn0-sC7U1H3-fmYUPsG-BUeAmo",
    blogImage:
      "https://website-blog-pictures.s3.eu-central-1.amazonaws.com/1631612173842.png",
  },
]
