import React from "react"

// Blog Articles data import
import { blogList } from "../../utilities/blogItems"

// Redux import
import { useSelector } from "react-redux"

// Components
import BlogComponent from "../../components/Tools/Blog/BlogComponent"
import TitleCard from "../../components/Tools/TitleCard/TitleCard"
import Section from "../../components/Tools/Section/Section"

// Localization
import blogData from "../../localized/blogLocalized.json"
import Layout from "../../components/Layout"

const Blog = () => {
  //Language
  const currentLanguage = useSelector(state => state.changeLanguageReducer)

  const titleCardIcons = ["128187", "128071", "128526"]

  const firstSection = (
    <div id="blog-grid">
      {blogList(currentLanguage).map(blog => {
        if (blog.title?.[currentLanguage] === "-") return null
        return (
          <BlogComponent
            key={blog.id}
            authorProfilePic={blog.authorImage}
            imgUrl={blog.blogImage}
            authorName={blog.author}
            title={blog.title?.[currentLanguage]}
            date={blog.data?.[currentLanguage]}
            id={blog.id}
          />
        )
      })}
    </div>
  )

  return (
    <Layout>
      <div id="blog-section">
        <TitleCard
          backgroundColor="gray"
          fontColor="black"
          title={blogData.articlesFromUs?.[currentLanguage]}
          icons={titleCardIcons}
        />
        <Section backgroundColor="white" sectionContent={firstSection} />
      </div>
    </Layout>
  )
}

export default Blog
